// Function to get URL parameter by name
function getUrlParameter(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

// Function to update the first H1 with the decoded URL parameter
function updateH1FromUrlParam() {
    // Get the 'utm_st_h1_1' parameter value and decode it
    const h1Text = getUrlParameter('utm_st_h1_1');
    if (h1Text) {
        // URL decode the parameter value
        const decodedText = decodeURIComponent(h1Text);
        
        // Find the first H1 element on the page and update its content
        const h1Element = document.querySelector('h1');
        if (h1Element) {
            h1Element.textContent = decodedText;
        }
    }
}

// Listen for the 'heyflow-screen-view' event to ensure the screen is loaded
window.addEventListener('heyflow-screen-view', (event) => {
    console.log('heyflow screen view:', event.detail);
    updateH1FromUrlParam(); // Call the function to update H1 when the screen loads
});

// Add this if you want to perform actions on submit as well
window.addEventListener('heyflow-submit', (event) => {
    console.log('heyflow submit:', event.detail);
});
